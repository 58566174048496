import React from "react"
import arrowDown from "../images/arrow-down.svg"

const CallForScroll = () => (
  <div className="call-for-scroll">
    <img
      src={arrowDown}
      className="has-margin-right-small"
      width={25}
      alt="arrow-down"
    />
    <p>scroll naar beneden voor meer</p>
  </div>
)

export default CallForScroll
