import React from "react"
import { Link, graphql } from "gatsby"
import { headlinesConverter } from "../utility/converters"
import Layout from "../components/layout"
import CallForScroll from "../components/call-for-scroll"

const Nieuws2025 = ({ data }) => {
  const headlinesByYear = headlinesConverter(data.allKrantenkoppenCsv.edges)
  const renderHeadlines = headlinesByYear.map(year => {
    const headlines = year.headlines.map(headline => {
      return (
        <div key={headline.title} className="headlines_headline_text">
          <h3>{headline.title}</h3>
          <p>{headline.subtitle}</p>
        </div>
      )
    })

    return (
      <div key={year.year} className="years-and-headlines">
        <div className="headlines_year">
          <h1>{year.year}</h1>
        </div>
        <div className="headlines_headlines-wrapper">{headlines}</div>
      </div>
    )
  })

  return (
    <Layout>
      <div className="headlines-page">
        <CallForScroll />
        <div className="headlines_wrapper">{renderHeadlines}</div>
        <div className="headlines_button">
          <Link className="button" to="/missie/">
            Ga naar onze missie
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default Nieuws2025

export const query = graphql`
  query Nieuws2025 {
    allKrantenkoppenCsv {
      edges {
        node {
          Jaartal
          Titel
          Subtitel
        }
      }
    }
  }
`
