import React from "react"

const commaTextToIntArray = text =>
  text.split(",").map(item => parseInt(item.trim()))

const statementConverter = statements => {
  const cleanStatements = statements.map(statement => {
    const highlights = commaTextToIntArray(statement.node.Highlighted_stories)
    const statementText = statement.node.Stelling
    const statementExplanation = statement.node.Uitleg_van_de_stelling
    return { highlights, statement: statementText, statementExplanation }
  })
  return cleanStatements
}

const headlinesConverter = headlines => {
  const yearMap = {}
  headlines.forEach(headline => {
    const key = headline.node.Jaartal
    if (!yearMap[key]) {
      yearMap[key] = [
        { title: headline.node.Titel, subtitle: headline.node.Subtitel },
      ]
    } else {
      yearMap[key].push({
        title: headline.node.Titel,
        subtitle: headline.node.Subtitel,
      })
    }
  })
  const yearsWithHeadlines = Object.keys(yearMap).map(year => ({
    year,
    headlines: yearMap[year],
  }))

  return yearsWithHeadlines
}

const getPersonsAndHightlights = (
  rawPersonsData,
  rawStoriesData,
  currentStatements
) => {
  //let's clean the data for the persons from the csv file
  const personsCleaned = rawPersonsData.map((person, index) => {
    const image = person.node.Plaatje
    const name = person.node.Naam
    const color = person.node.Kleur
    const storyIds = commaTextToIntArray(person.node.Id_stories)
    const id = index
    return { image, name, storyIds, color, id }
  })
  //let's clean the data for the stories from the csv file
  const storiesCleaned = rawStoriesData.map(rawStory => {
    const id = parseInt(rawStory.node.Id_stories)
    const date = rawStory.node.Datum
    const image = rawStory.node.Plaatje
    const story = rawStory.node.Stories
    const title = rawStory.node.Titel_stories
    const personId = parseInt(rawStory.node.Id_person)
    const highlighted = currentStatements.includes(id)
    return { id, date, image, story, title, personId, highlighted }
  })

  const personWithStories = personsCleaned.map(personInfo => {
    const stories = storiesCleaned.filter(story =>
      personInfo.storyIds.includes(story.id)
    )
    const highlighted = stories.filter(story => story.highlighted)
    return { personInfo, stories, highlighted }
  })

  return personWithStories
}

const getCommunityStories = rawCommunityData => {
  const communityDataCleaned = rawCommunityData.map(entry => {
    const date = entry.node.Datum
    const story = entry.node.Stories
    return { date, story }
  })
  return communityDataCleaned
}

const convertStoryToMarkdown = story => {
  return story
    .split("*enter*")
    .map((storyParagraph, index) => <p key={index}>{storyParagraph}</p>)
}

export {
  statementConverter,
  getPersonsAndHightlights,
  headlinesConverter,
  getCommunityStories,
  convertStoryToMarkdown,
}
